'use client' // Error components must be Client Components

import { ErrorCard } from '_/ui/error-card'
import Button from '_/ui/button'
import { Suspense, useEffect } from 'react'
import { appRouterSsrErrorHandler, AppRouterErrorProps } from '@highlight-run/next/ssr'

export default appRouterSsrErrorHandler(({ error, reset }: AppRouterErrorProps) => {
	useEffect(() => {
		// eslint-disable-next-line no-console
		console.error(error)
	}, [error])

	return (
		<div className='flex justify-center items-center flex-col mx-4 bg-neutral-300'>
			<Suspense fallback={<div>Loading...</div>}>
				<ErrorCard error={error} />
			</Suspense>
			<Button type='button' onClick={reset} className='shrink' themeColor='outline'>
				Try Again
			</Button>
		</div>
	)
})
