type ErrorCardProps = any

export const ErrorCard = ({ error }: { error?: ErrorCardProps }) => {
	if (!error || process.env.NODE_ENV !== 'development') {
		return null
	}

	if (!error) {
		return null
	}

	const errorStringified = (typeof error === 'string' ? error : JSON.stringify(error, null, 2)) as string

	return (
		<div className='w-full p-4 overflow-hidden'>
			<div className='rounded overflow-hidden'>
				<div className='bg-red-400 text-white px-4 pb-1 pt-1.5'>
					<h3 className='text-md flex justify-between leading-4'>
						⚠︎ <span className='text-red-800 font-mono'>ERROR</span>
					</h3>
				</div>
				<div className='p-4 bg-neutral-200'>
					<pre className='text-gray-700'>{errorStringified}</pre>
				</div>
			</div>
		</div>
	)
}
